<template>
  <div class="dashboard-wrap">
    <div class="dashboard">
      <div class="top-bar flex justify-between">
        <div class="flex items-center">
          <span class="font-bold">消息通知</span>
        </div>
        <a-button @click="$router.go(-1)" type="primary">返回工作台</a-button>
      </div>
      <a-table
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="pagination"
        :showHeader="false"
        @change="handleTableChange"
      >
        <template #imgSrc="imgSrc">
          <img :src="imgSrc" alt="imgSrc" class="imgItem" />
        </template>
        <template #information="information">
          <div
            class="information"
            @click="handleClick(information.id, information.status)"
          >
            <div class="information_title">{{ information.title }}</div>
            <div class="information_content">
              <span>{{ information.info }}</span>
              <span>{{ information.time }}</span>
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { noticeSmsList, noticeSmsSetRead } from '@/api/Official'
import moment from 'moment'

export default {
  name: 'BookReview',
  data() {
    return {
      columns: [
        {
          title: 'imgSrc',
          dataIndex: 'imgSrc',
          width: 70,
          scopedSlots: { customRender: 'imgSrc' },
        },
        {
          title: 'information',
          dataIndex: 'information',
          scopedSlots: { customRender: 'information' },
        },
      ],
      list: [],
      pagination: {
        current: 1,
        defaultPageSize: 6,
        total: 0,
        showTotal: (total) => `共${total}条`,
      },
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    ...mapState('user', { infoChange: 'infoChange' }),
  },
  methods: {
    ...mapActions('user', ['setInfoChange']),
    // 获取消息列表
    getList(page = 1) {
      this.$store.dispatch('loading/startContentLoading')
      noticeSmsList({ page })
        .then((res) => {
          this.setInfoChange(this.infoChange + 1)

          // 定义分页信息
          this.pagination.defaultPageSize = res.data.per_page || 6
          this.pagination.total = res.data.total || 0

          this.list = (res.data.data || []).map((item) => {
            const order = item.order
            return {
              id: item.id,
              imgSrc:
                item.status === 0
                  ? require('@/assets/unread.svg')
                  : require('@/assets/read.svg'),
              information: {
                id: item.id,
                status: item.status,
                title: `${order.to_user_bind_shop[0].nickname}${
                  order.order_date
                }为${order.name}${order.sex === 1 ? '先生' : '女生'}预订的${
                  order.order_type === 1 ? '午餐' : '晚餐'
                }，包间名：${order.to_room.title}。请电话联系顾客去确认！`,
                info: `${item.address}回复：“${item.content}”`,
                time: item.timestamp
                  ? moment(new Date(item.timestamp * 1000)).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : '--',
              },
            }
          })
        })
        .finally(() => {
          this.$store.dispatch('loading/endContentLoading')
        })
    },

    // 分页
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.getList(pagination.current)
    },

    // 点击切换已读
    handleClick(id, status) {
      if (status === 1) return false
      noticeSmsSetRead({ id }).then(() => {
        this.getList()
      })
    },
  },
}
</script>

<style scoped lang="less">
.dashboard-wrap {
  background-color: #f0f2f5;
  padding: 24px;
  box-sizing: border-box;
}

.dashboard {
  width: 100%;
  min-height: calc(100vh - 114px);
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 22px;
}

.top-bar {
  margin-bottom: 30px;
}

.imgItem {
  height: 40px;
  width: 40px;
}

.information {
  .information_title {
    font: 500 16px/18px 'PingFangSC-Medium';
    color: #333;
  }
  .information_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    font: 400 14px/14px 'PingFangSC-Regular';
    color: #666;
  }
}
</style>
